function fireEvent(name, options) {
    return document.dispatchEvent(new CustomEvent(`inertia:${name}`, options))
}

export const fireBeforeEvent = (visit) => {
    return fireEvent('before', { cancelable: true, detail: { visit } })
}

export const fireErrorEvent = (errors) => {
    return fireEvent('error', { detail: { errors } })
}

export const fireExceptionEvent = (exception) => {
    return fireEvent('exception', { cancelable: true, detail: { exception } })
}

export const fireFinishEvent = (visit) => {
    return fireEvent('finish', { detail: { visit } })
}

export const fireInvalidEvent = (response) => {
    return fireEvent('invalid', { cancelable: true, detail: { response } })
}

export const fireNavigateEvent = (page) => {
    return fireEvent('navigate', { detail: { page } })
}

export const fireProgressEvent = (progress) => {
    return fireEvent('progress', { detail: { progress } })
}

export const fireStartEvent = (visit) => {
    return fireEvent('start', { detail: { visit } })
}

export const fireSuccessEvent = (page) => {
    return fireEvent('success', { detail: { page } })
}
